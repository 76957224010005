import React from 'react'

export function Company() {
  return (
    <div className="company">
      <div>有尺物隶属于上海潮办信息科技有限公司。</div>
      <div>
        上海潮办信息科技有限公司成立于2019年，是一家辐射全球的科技型创新企业，目前公司基于微信平台推出了「一番赏ONLINE」和「有尺物」。
      </div>
      <div>
        一番赏ONLINE：一番赏官方在线购平台，版权IP覆盖有：航海王、龙珠、鬼灭之刃、EVA、高达、夏目友人帐等。
      </div>
      <div>有尺物：线上赏品分享与交易类社区。</div>
    </div>
  )
}
