import * as React from 'react'
import uchiwu from '../assets/uchiwu.jpg'
import yifanshang from '../assets/yifanshang.jpg'

export function Home() {
  return (
    <div className="home">
      <div>有尺物，有态度</div>
      <div className="pics">
        <div className="pic">
          <img src={uchiwu} alt="uchiwu" />
          <div>有尺物</div>
        </div>
        <div className="pic">
          <img src={yifanshang} alt="yifanshang" />
          <div>一番赏 ONLINE</div>
        </div>
      </div>
    </div>
  )
}
