import * as React from 'react'

export function Contact() {
  return (
    <div className="contact">
      <p>联系我们</p>
      <p>上海市浦东新区海洋一路 333 号临港科技创业中心 8 号楼 4 层</p>
    </div>
  )
}
