/* eslint-disable react/no-unsafe */
// @ts-nocheck
import * as React from 'react'
import './style.less'
import bg1 from '../assets/jump_bg_1.png'
import bg2 from '../assets/jump_bg_2.png'
import btn from '../assets/jump_btn.png'

export default class Jump extends React.PureComponent {
  componentDidMount() {
    function docReady(fn) {
      if (document.readyState === 'complete' || document.readyState === 'interactive') {
        fn()
      } else {
        document.addEventListener('DOMContentLoaded', fn)
      }
    }

    docReady(async () => {
      let ua = navigator.userAgent.toLowerCase()
      let isWXWork = ua.match(/wxwork/i) === 'wxwork'
      let isWeixin =
        !isWXWork &&
        ua.match(/MicroMessenger/i) &&
        ua.match(/MicroMessenger/i)[0] === 'micromessenger'
      let isMobile = false
      let isDesktop = false
      if (
        navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)
      ) {
        isMobile = true
      } else {
        isDesktop = true
      }
      console.log('isWeixin', isWeixin)
      console.log('isDesktop', isDesktop)
      console.log('isMobile', isMobile)

      if (isWeixin) {
        let containerEl = document.getElementById('wechat-web-container')
        containerEl.classList.remove('hidden')
        containerEl.classList.add('full', 'wechat-web-container')

        let launchBtn = document.getElementById('launch-btn')
        launchBtn.addEventListener('ready', function (e) {
          console.log('开放标签 ready')
        })
        launchBtn.addEventListener('launch', function (e) {
          console.log('开放标签 success')
        })
        launchBtn.addEventListener('error', function (e) {
          console.log('开放标签 fail', e.detail)
        })

        window.wx.config({
          debug: false, // 调试时可开启
          appId: 'wx0de84352297017ac', // <!-- replace -->
          timestamp: 0, // 必填，填任意数字即可
          nonceStr: 'nonceStr', // 必填，填任意非空字符串即可
          signature: 'signature', // 必填，填任意非空字符串即可
          jsApiList: ['openWeApp'], // 必填，随意一个接口即可
          openTagList: ['wx-open-launch-weapp'] // 填入打开小程序的开放标签名
        })
      } else if (isDesktop) {
        // 在 pc 上则给提示引导到手机端打开
        const containerEl = document.getElementById('desktop-web-container')
        containerEl.classList.remove('hidden')
        containerEl.classList.add('full', 'desktop-web-container')
      } else {
        const containerEl = document.getElementById('public-web-container')
        containerEl.classList.remove('hidden')
        let c = new window.cloud.Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: 'wx0de84352297017ac', // <!-- replace -->
          // 资源方环境 ID
          resourceEnv: 'prod-2bgcb' // <!-- replace -->
        })
        await c.init()
        window.c = c
        try {
          await this.openWeapp(() => {})
        } catch (e) {
          throw e
        }
      }
    })
  }

  async openWeapp(onBeforeJump) {
    let c = window.c
    console.log(c, window)
    const res = await c.callFunction({
      name: 'public',
      data: {
        action: 'getUrlScheme'
      }
    })
    console.warn(res)
    if (onBeforeJump) {
      onBeforeJump()
    }
    window.location.href = res.result.openlink
  }
  render() {
    return (
      <div className="page ">
        {/* 手机环境内容  检测id内容 */}
        <div id="public-web-container" className="hidden">
          <div className="web-main">
            <img className="web-bg" src={bg1} alt="bg" />
            <img className="web-btn" src={btn} alt="btn" onClick={() => this.openWeapp()} />
            <img className="web-bg web-bg2" src={bg2} alt="bg" />
          </div>
        </div>

        {/* 微信环境 */}
        <div id="wechat-web-container" className="hidden">
          <div className="wechat-main">
            <wx-open-launch-weapp
              id="launch-btn"
              username="gh_be66b2fea696"
              path="pages/index/index?inviteCode=xiaopanghua"
            >
              <script type="text/wxtag-template">
                <button
                  style={{
                    width: '100%',
                    height: '320px',
                    backgroundColor: '#f58220',
                    color: '#fff'
                  }}
                >
                  打开小程序
                </button>
              </script>
            </wx-open-launch-weapp>
          </div>
        </div>

        {/* web环境 */}
        <div id="desktop-web-container" className="hidden">
          <p className="">请在手机打开网页链接</p>
        </div>
      </div>
    )
  }
}
