import React from 'react'

import uchiwu from '../assets/uchiwu.jpg'
import yifanshang from '../assets/yifanshang.jpg'
import product1 from '../assets/product_1.jpg'
import product2 from '../assets/product_2.jpg'
import product3 from '../assets/product_3.jpg'

export function Product() {
  return (
    <div className="product">
      <div className="pics">
        <div className="pic">
          <img src={uchiwu} alt="uchiwu" />
          <div>有尺物</div>
        </div>
        <div className="pic">
          <img src={yifanshang} alt="yifanshang" />
          <div>一番赏 ONLINE</div>
        </div>
      </div>
      <div className="pic large">
        <img src={product1} alt="product1" />
        <div>
          一番赏官方在线购 部分产品信息：航海王 和之国第二幕
          （58元/抽，随机获得手办，公仔，挂件等正版周边产品）
        </div>
      </div>
      <div className="pic large">
        <img src={product2} alt="product1" />
        <div>
          一番赏官方在线购 部分产品信息：龙珠 VS Z
          （58元/抽，随机获得手办，公仔，挂件等正版周边产品）
        </div>
      </div>
      <div className="pic large">
        <img src={product3} alt="product1" />
        <div>
          一番赏官方在线购 部分产品信息：新世纪福音战士
          初号机觉醒（58元/抽，随机获得手办，公仔，挂件等正版周边产品）
        </div>
      </div>
    </div>
  )
}
