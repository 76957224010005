import { RouteProps } from 'react-router'
import { Home, Contact, Product, Company } from '../pages'

export interface IRouteProps extends RouteProps {
  menu?: boolean
  title?: string
}

export const appRoutes: IRouteProps[] = [
  {
    path: '/',
    component: Home
  },
  {
    title: '产品介绍',
    path: '/products',
    component: Product
  },
  {
    title: '公司介绍',
    path: '/company',
    component: Company
  },
  {
    title: '联系我们',
    path: '/contact',
    component: Contact
  }
]
